//Bootstrap
import { Card, InputGroup, Button, Row, Col } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

//Hooks
import { useDataGouv } from "../hooks/useDataGouv";

//React
import { useEffect, useState } from "react";

//Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useMapInfo } from "../hooks/useMapInfo";
import { set } from "react-hook-form";

function CardAddressComponent() {
    /**
     * Hooks
     */
    const { apiAddress } = useDataGouv();
    const { setStoreAddress } = useMapInfo();

    /**
     * States
     */
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultAddress, setDefaultAddress] = useState(null);

    /**
     * useEffect
     */
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const address = urlParams.get('address');
        const postcode = urlParams.get('postcode');
        const city = urlParams.get('city');
        const citycode = urlParams.get('citycode');
        const lat = urlParams.get('lat');
        const lon = urlParams.get('lon');
        if (address && lat && lon && citycode && postcode && city) {
            setTimeout(() => {
                setDefaultAddress(address+' '+postcode+' '+city);
            }, 1000);
            setStoreAddress({
                label: address+' '+postcode+' '+city,
                postcode: postcode,
                city: city,
                citycode: citycode,
                street: address,
                coordinates: {
                    latitude: lat,
                    longitude: lon
                }
            });
        }
        else {
            setDefaultAddress('');
        }
    }, []);

    /**
     * Handlers
     */
    const handleSearch = (query) => {
        setIsLoading(true);
        
        apiAddress(query, 5)
            .then((response) => {
                var options = response.features.map((feature) => {
                    return {
                        id: feature.properties.id,
                        label: feature.properties.label,
                        postcode: feature.properties.postcode,
                        housenumber: feature.properties.housenumber,
                        street: feature.properties.street,
                        city: feature.properties.city,
                        citycode: feature.properties.citycode,
                        score: feature.properties.score,
                        coordinates: {
                            latitude: feature.geometry.coordinates[1],
                            longitude: feature.geometry.coordinates[0]
                        }
                    }
                });

                setOptions(options);
                
                setIsLoading(false);
            })
            .catch((error) => {
                
            });
    }

    const handleChange = (selected) => {
        setSelectedOption(selected[0]);
        handleClick(selected[0]);
    }

    const handleClick = (selectedValue = null) => {
        //On centre la carte sur l'adresse sélectionnée
        setStoreAddress(selectedValue??selectedOption);
    }

    /**
     * Functions
     */
    const filterBy = () => true;
    
    /**
     * Render
     */
    return (
        <>
            {defaultAddress !== null && 
                <Card className="card-address">
                    <Card.Body>
                        <Row>
                            <Col>
                                <InputGroup className="card-address-inputgroup">
                                    <AsyncTypeahead
                                        filterBy={filterBy}
                                        id="search-address"
                                        isLoading={isLoading}
                                        labelKey="label"
                                        minLength={4}
                                        onSearch={handleSearch}
                                        onChange={handleChange}
                                        options={options}
                                        placeholder="Entrez une adresse"
                                        positionFixed={true}
                                        defaultInputValue={defaultAddress||''}
                                        renderMenuItemChildren={(option, props) => (
                                            <>
                                                <span>{(option as any).label}</span>
                                            </>
                                        )}
                                    />
                                    <Button variant="primary" id="btn-adress" onClick={handleClick} disabled={!selectedOption}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export default CardAddressComponent;